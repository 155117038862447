import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '../../shared/helperService';

@Component({
  selector: 'app-back-btn',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss'],
})
export class BackButtonComponent implements OnInit {

  @Output() goBack = new EventEmitter();
  @Output() clearAll = new EventEmitter();
  @Input() title: string = '';
  @Input() icon: string = '';
  @Input() defaultHref: string = '';

  constructor(
    private router: Router,
    private helperService: HelperService
  ) { }

  ngOnInit() { }



  back(): void {
    if (this.defaultHref) {
      this.helperService.routeNavigate(this.defaultHref)
    } else {
      this.goBack.emit();
    }
    this.helperService.scrollToTop();
  }
  clear(): void {
    this.clearAll.emit();
  }
}
