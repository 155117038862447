import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
})
export class SvgComponent implements OnInit {

  @Input() pl: string;
  @Input() leftQuote: boolean = false;
  @Input() quoteColor: string = '#000';
  @Input() isCancel: boolean = false;
  @Input() isGoogle: boolean = false;
  @Input() isDropdown: boolean = false;
  @Input() gotoTop:boolean = false;
  @Input() searchIcon:boolean = false;
  @Input() profileIcon:boolean = false;

  constructor() { }

  ngOnInit() {}

}
