import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppInjector } from './app-injector.service';

if (environment.production) {
  enableProdMode();
}


function bootstrap() {
       platformBrowserDynamic().bootstrapModule(AppModule).then((moduleRef) => {
  AppInjector.setInjector(moduleRef.injector);
}).catch(err => console.log(err));
     };


if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}




