import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { map, catchError, switchMap, retry, filter } from 'rxjs/operators';
import { AsyncValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export abstract class LookupServiceBase {

  constructor() {
  }

  getData(type: string, userFilter: boolean = false) {
    const service = this.getService(type);
    if (service != null) {
      return service.getAll(userFilter) as Observable<any[]>;
    } else {
      return of([]);
    }
  }

  getDataEnum(type: string) {
    const service = this.getService('EnumDetail');
    // console.log('service', service);
    if (service != null) {
      // return service.getAll(); // as Observable<any[]>;
      return (service.getAll() as Observable<any[]>).pipe(
        map(a => a.filter(b => b['section'] === type))
      );

    } else {
      return of([]);
    }
  }

  getLabelEnum(type: string, value: string) {
    const service = this.getService('EnumDetail');
    if (service != null) {
      // return service.getAll(); // as Observable<any[]>;
      return (service.getAll() as Observable<any[]>).pipe(
        map(a => a.filter(b => b['section'] === type && b['value'] === value))
      );

    } else {
      return of([]);
    }
  }

  searchData(type: string, search: any) {
    const service = this.getService(type);
    if (service != null) {
      return service.searchData(search) as Observable<any[]>;
    } else {
      return of([]);
    }
  }

 getService(type: string) {
    let service: any = null;
    return service;
  }



  uniqueNameValidator(type: string, column: string, id: any): AsyncValidatorFn {

    const service = this.getService(type);
    // const itemUniuqeCheck:any = {id:item.id}
    return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
      const parent: any = control['parent'];
      if (parent instanceof FormGroup) {
        //console.log('parent', parent);
        //console.log('control', control, control.touched, control.dirty);
      }

      if ((!control.touched) && (!control.dirty)) {
        return of(null);
      }

      if (control.value) {
        const condition: any = { 'Id': id };
        const columnName = column.charAt(0).toUpperCase() + column.slice(1);
        condition[columnName] = control.value;

        const newItem = { ...parent.value };
        newItem['id'] = id;
        return service.unique(columnName, newItem, condition)
          .pipe(
            map(isUnique => {
              //console.log('isUnique', isUnique);
              if (isUnique) {
                return { 'uniqueNameExists': true };
              }
            })
          );
      } else {
        return of(null);
      }
    };

  }
}
