import {
  ElementRef,
  Injectable,
  Renderer2,
  RendererFactory2,
} from "@angular/core";
import { Subject, of, Observable, observable, from } from "rxjs";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export abstract class HelperServiceBase {
  searchHistory: any[] = [];
  loggedInUser = undefined;
  token: string = "";
  tokenName = "token";
  expireTokenName = "expireTokenName";
  tokenInfo = "tokenInfo";
  public globalLoading = false;
  public loginChange = new Subject<string>();
  public loggedInUserComplete = new Subject<Object>();
  public renderer: Renderer2;
  urlNavigation = "";
  routeData: any = null;
  constructor(
    public router: Router,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  clearInfo() {
    localStorage.removeItem(this.tokenInfo);
    localStorage.removeItem(this.tokenName);
    this.loggedInUser = null;
  }

  logout() {
    localStorage.removeItem(this.tokenInfo);
    localStorage.removeItem(this.tokenName);

    this.loggedInUser = undefined;
    this.loginChange.next("logout");
    this.routeNavigate(["login"]);
    // this.routeNavigate(['login']);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 50);
  }

  routeNavigate(path: any) {
    if (this.isAdminUser) {
      if (path.indexOf("admin/") < 0) {
        this.router.navigate(["./" + "admin/" + path]);
      } else {
        this.router.navigate(["./" + path]);
      }
    } else {
      this.router.navigate(["./" + path]);
    }
  }
  setActiveRouteData(path: string) {
    const currentUrl = this.router.url;
    const routeUrl = currentUrl.split("#")[0];
    return routeUrl.toLowerCase() == path.toLowerCase();
  }

  // getStorageValue (keyName: any){
  //   return from (of(localStorage.getItem(keyName)))
  // }

  // setStorageValue (keyName: any, value: any){
  //   localStorage.setItem(keyName,value);
  // }

  // removeStorage(keyName: any){
  //   localStorage.removeItem(keyName);
  // }

  // getLoggedInUserInfo(): any | undefined {
  //   if (this.loggedInUser) {
  //     //console.log('this.loggedInUser', this.loggedInUser);
  //     this.loggedInUserComplete.next(this.loggedInUser);
  //     return this.loggedInUser;
  //   } else {

  //     this.getStorageValue(this.tokenInfo).subscribe((val) => {
  //       if (val) {
  //         this.getStorageValue(this.tokenName).subscribe((valToken) => {
  //           this.token = valToken;
  //         });

  //         try {

  //           const userInfo = JSON.parse(atob(val));
  //           this.loggedInUser = userInfo[0];
  //           console.log('parsed this.loggedInUser', this.loggedInUser);
  //           this.loggedInUserComplete.next(this.loggedInUser);
  //           return this.loggedInUser;
  //           //console.log('this.loggedInUser', this.loggedInUser);
  //         } catch (ex) {
  //           // localStorage.removeItem(this.tokenInfo);
  //           // localStorage.removeItem(this.tokenName);
  //           this.removeStorage(this.tokenInfo);
  //           this.removeStorage(this.tokenName);
  //           this.router.navigate(['/login']);
  //         }
  //       } else {
  //         this.removeStorage(this.tokenInfo);
  //         this.removeStorage(this.tokenName);
  //         this.router.navigate(['/login']);
  //       }
  //       return null;
  //     });
  //   return null;
  //   }
  //   return null;
  // }

  getLoggedInUserInfo() {
    if (this.loggedInUser) {
      this.loggedInUserComplete.next(this.loggedInUser);
      // console.log('this.loggedInUser', this.loggedInUser,this.tokenName);
      return this.loggedInUser;
    } else {
      const val = localStorage.getItem(this.tokenInfo);
      if (val) {
        this.token = localStorage.getItem(this.tokenName);
        try {
          const userInfo = JSON.parse(atob(val));
          this.loggedInUser = userInfo[0];
          this.loggedInUserComplete.next(this.loggedInUser);
          return this.loggedInUser;
        } catch (ex) {
          // localStorage.removeItem(this.tokenInfo);
          // localStorage.removeItem(this.tokenName);
          // this.storage.remove(this.tokenInfo);
          // this.storage.remove(this.tokenName);
          //this.router.navigate(['home']);
        }
      } else {
        // localStorage.removeItem(this.tokenInfo);
        // localStorage.removeItem(this.tokenName);
        //this.router.navigate(['home']);
      }

      // if (localStorage.getItem(this.tokenInfo)) {
      //   try {

      //     const userInfo = JSON.parse(atob(localStorage.getItem(this.tokenInfo)));
      //     this.loggedInUser = userInfo[0];
      //    // console.log('parsed this.loggedInUser', this.loggedInUser,this.tokenName);
      //     return this.loggedInUser;
      //     //console.log('this.loggedInUser', this.loggedInUser);
      //   } catch (ex) {
      //     localStorage.removeItem(this.tokenInfo);
      //     localStorage.removeItem(this.tokenName);
      //   }
      // } else {
      //   console.log('parsed', this.loggedInUser);
      //   localStorage.removeItem(this.tokenName);
      // }
    }
    return null;
  }

  setLoggedInUserInfo(userInfo) {
    this.loggedInUser = null;
    localStorage.setItem(this.tokenInfo, btoa(userInfo));
    this.loginChange.next("login");
  }
  // setLoggedInUserInfo(userInfo: any) {
  //   const userInfoObj = JSON.parse(userInfo);
  //   this.loggedInUser = userInfoObj[0];
  //   this.setStorageValue(this.tokenInfo, btoa(userInfo));
  //   this.loginChange.next('login');
  // }

  setUserInfo(userInfo: any) {
    const data = [];
    data.push(userInfo);
    this.setLoggedInUserInfo(JSON.stringify(data));
  }

  get isUserLoggedIn() {
    return this.getLoggedInUserInfo() !== null;
  }
  set globalLoadingVal(val: boolean) {
    this.globalLoading = val;
  }

  get isAdminUser() {
    const loggedInUser = this.getLoggedInUserInfo();
    // console.log('isAdminUser => loggedInUser', loggedInUser);
    return loggedInUser !== null && loggedInUser.isAdmin;
  }
  get userRole() {
    const loggedInUser = this.getLoggedInUserInfo();
    // console.log('isAdminUser => loggedInUser', loggedInUser);
    return loggedInUser !== null && loggedInUser.role;
  }

  set navigationUrl(url: string) {
    this.urlNavigation = url;
  }

  get navigationUrl() {
    const url = this.urlNavigation;
    this.urlNavigation = "";
    return url || "/home";
  }

  // getToken() {
  //   console.log('getToken', this.token);
  //   return this.token;
  // }
  // setToken(token:any) {
  //   console.log('setToken', token);
  //   this.token = token;
  //   this.setStorageValue(this.tokenName, token);
  // }
  setToken(token) {
    localStorage.setItem(this.tokenName, token);
  }
  setExpireToken(dateToken) {
    localStorage.setItem(this.expireTokenName, dateToken);
  }
  getExpireToken() {
    return localStorage.getItem(this.expireTokenName);
  }
  getSearchHistory(path: any) {
    const search = this.searchHistory.filter((a) => a.path === path);
    if (search && search.length > 0) {
      return { ...search[0].search };
    }
    return null;
  }

  setSearchHistory(path: any, filter: any) {
    const search = this.searchHistory.filter((a) => a.path === path);
    if (search && search.length > 0) {
      search[0].search = filter;
    } else {
      this.searchHistory.push({ path: path, search: filter });
    }
  }

  scrollToTop(): void {
    let ionContent = document.getElementsByTagName("ion-content");
    let elemDivs = ionContent[1].getElementsByTagName("div");
    // console.log('elem',elemDivs);
    elemDivs[0].scrollIntoView({ behavior: "smooth", block: "start" });
  }

  scrollToSection(id: string): void {
    let elem = document.getElementById(id);
    elem?.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  clearSearchHistory() {
    this.searchHistory = [];
  }

  convertTitleCase(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  convertCamelCase(text: string) {
    return text.charAt(0).toLocaleLowerCase() + text.slice(1);
  }

  reload() {
    const currentUrl = decodeURIComponent(this.router.url);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.routeNavigate(currentUrl);
    });
  }

  setRouteData(data: any) {
    this.routeData = data;
  }
  getRouteData() {
    const data = { ...this.routeData };
    this.routeData = null;
    return data;
  }

  getFooterPages() {
    const pages = [
      {
        icon: "home.svg",
        text: "Home",
        url: "/home",
      },
      {
        icon: "platters.svg",
        text: "Platters",
        url: "/platters/home",
      },
      {
        icon: "my-portfolio.svg",
        text: "Portfolio",
        url: "/investment-report",
      },
      {
        icon: "funds.svg",
        text: "Funds",
        url: "/funds/home",
      },
      {
        icon: "accounts.svg",
        text: "Tools",
        url: "/tools",
      },
    ];
    return [...pages];
  }

  getCurrentRouteUrl() {
    return this.router.url;
  }

  getPlatterRiskProfile() {
    const userInfo = this.getLoggedInUserInfo();
    if (userInfo.riskProfile) {
      switch (userInfo.riskProfile) {
        case "Defensive":
          return "Low";
          break;
        case "Conservative":
          return "Moderately Low";
          break;
        case "Moderate":
          return "Moderate";
          break;
        case "Growth":
          return "Moderately High";
          break;
        case "Aggressive":
          return "High";
          break;
      }
    }
    return "";
  }

  checkValidControls(itemForm: any, controls: string[], reactiveForm = false) {
    //let valid = true;
    const formControls = reactiveForm
      ? itemForm?.controls
      : itemForm?.form.controls;

    for (var key in formControls) {
      formControls[key].touched = true;
    }

    var hasInvalid = controls.some(function (ctrl) {
      return !(formControls[ctrl] ? formControls[ctrl].valid : true);
    });
    return !hasInvalid;

    // controls.forEach(ctrl => {
    //   valid = reactiveForm ? itemForm?.controls[ctrl]?.valid : itemForm?.form.controls[ctrl]?.valid;
    //   if(!valid) return false
    // });
    // return valid;
  }

  public keyPressAlphaNumeric(event: any) {
    // regEx = regEx || "/[A-Z0-9]+/g"
    console.log(event);
    console.log("Target: ", event.target.value);
    const keyValue = event.target.value;

    var re = /[a-zA-Z0-9]+/g;
    const PanValue = (keyValue.match(re) || []).join("");
    event.target.value = PanValue;
  }
  public keyNumeric(event: any) {
    console.log(event);
    console.log("Target: ", event.target.value);
    const keyValue = event.target.value;
    var re = /[0-9]+/g;
    const inputValue = (keyValue.match(re) || []).join("");
    event.target.value = inputValue;
  }

  get isMobile(): boolean {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  public convertToMinSec(totalSeconds: number): string {
    totalSeconds = Math.round(totalSeconds);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }
  public convertTohrMin(totalMinit: number): string {
    totalMinit = Math.round(totalMinit);
    const hours = Math.floor(totalMinit / 60);
    const minutes = totalMinit % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  public scrollParentToChild(parent, child) {
    // Where is the parent on page
    var parentRect = parent.getBoundingClientRect();
    // What can you see?
    var parentViewableArea = {
      height: parent.clientHeight,
      width: parent.clientWidth,
    };

    // Where is the child
    var childRect = child.getBoundingClientRect();
    // Is the child viewable?
    var isViewable =
      childRect.top >= parentRect.top &&
      childRect.top <= parentRect.top + parentViewableArea.height;

    // if you can't see the child try to scroll parent
    if (!isViewable) {
      // scroll by offset relative to parent
      parent.scrollTop = childRect.top + parent.scrollTop - parentRect.top;
    }
  }
}
