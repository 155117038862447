import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sortby-filter',
  templateUrl: './sortby-filter.component.html',
  styleUrls: ['./sortby-filter.component.scss'],
})
export class SortbyFilterComponent implements OnInit {

  @Input() isFilterVisible:boolean = false;
  @Input() isSortByVisible:boolean = false;

  isSortVisible: boolean = false;
  isFilVisible:boolean = false;
  position: string;

  constructor() { }

  ngOnInit() {
  }

  showDialog(elem:string, position: string):void {
    if(elem.toLocaleLowerCase() === 'sort') {
      this.position = position;
      this.isSortVisible = true;

    }

    if(elem.toLocaleLowerCase() === 'filter') {
      this.position = position;
      this.isFilVisible = true;

    }
  }

  closeDialog(elem:string, position: string):void {
    if(elem.toLocaleLowerCase() === 'sort') {
      this.position = position;
      this.isSortVisible = false;
    }

    if(elem.toLocaleLowerCase() === 'filter') {
      this.position = position;
      this.isFilVisible = false;
    }
  }



}
