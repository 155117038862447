import { Injector } from '@angular/core';

export class AppInjectorBase {

  static injector: Injector;

  static setInjector(injector: Injector) {

    AppInjectorBase.injector = injector;

  }

  static getInjector(): Injector {

    return AppInjectorBase.injector;

  }

}
