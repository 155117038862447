import {Pipe, PipeTransform} from '@angular/core';
@Pipe({ name: 'htmlContent' })
export class HtmlContent implements PipeTransform {
  transform(value:any[], filter:string, data:any = null) {
    console.log('htmlcontent', value, filter);
    if(!value || (!filter)) {
      return '';
    }
    const dataFilter= value.filter((item, idx) => { return item.name == filter } );
    if(dataFilter && dataFilter.length>0){
      let html: string = dataFilter[0].html;
      if(data){
        Object.keys(data).forEach(e => {
          const searchRegExp = new RegExp('{{'+e +'}}', 'g');
          const replaceWith = data[e];
          html = html.replace(searchRegExp,replaceWith);
        });
      }
      return html;
    }
    return '';
  }
}
