import { Injectable } from '@angular/core';
import { ExprienceLatter } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class ExprienceLattersService extends BaseService<ExprienceLatter> {
    constructor() {
        super('ExprienceLatter');
    }
}
