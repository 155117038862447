import {Pipe, PipeTransform} from '@angular/core';
@Pipe ({
   name : 'gridRowSelect'
})
export class GridRowSelectPipe implements PipeTransform {
   transform(row: { id: number; }, selectedList: any, showSelect: string = 'multiple'): boolean {
    if (showSelect === 'multiple') {
      return selectedList.some( (item: { id: number; }) => item.id === row.id);
    }
    if (showSelect === 'single') {
      return  selectedList.id === row.id;
    }
    return false;
   }
}
