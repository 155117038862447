import {Pipe, PipeTransform} from '@angular/core';
@Pipe ({
   name : 'gridColumnShow'
})
export class GridColumnShowPipe implements PipeTransform {
   transform(showColumn: string[], column: string = ''): boolean {
    if (showColumn.length) {
      return showColumn.indexOf(column) >= 0;
    }
    return true;
   }
}
