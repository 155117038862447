import { Injectable } from '@angular/core';
import { Configuration } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class ConfigurationsService extends BaseService<Configuration> {
    constructor() {
        super('Configuration');
    }
}
