import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { map, catchError, switchMap, retry, filter } from 'rxjs/operators';
import { AsyncValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

import * as services from '../core/service';

 import { LookupServiceBase } from '../sharedBase/lookupService';
//import { LookupServiceBase } from '@ojas-enterprise/baseapp/dist/sharedBase';
import { AppInjector } from '../../app-injector.service';

@Injectable({
  providedIn: 'root'
})
export class LookupService extends LookupServiceBase {

  getService(type: string) {
    let service: any = null;
    const injector = AppInjector.getInjector();
    //console.log('injector', injector);
    type = type[0].toLowerCase() + type.substring(1);
    switch (type) {

      case 'user':
        service = injector.get(services.UsersService);
        break;
 case 'webUser':
service = injector.get(services.WebUsersService);
break;
 case 'enumDetail':
service = injector.get(services.EnumDetailsService);
break;
 case 'appUser':
service = injector.get(services.AppUsersService);
break;
 case 'salary':
service = injector.get(services.SalariesService);
break;
 case 'login':
service = injector.get(services.LoginsService);
break;
 case 'offerLatter':
service = injector.get(services.OfferLattersService);
break;
 case 'home':
service = injector.get(services.HomesService);
break;
 case 'configuration':
service = injector.get(services.ConfigurationsService);
break;
 case 'exprienceLatter':
service = injector.get(services.ExprienceLattersService);
break;
 case 'increment':
service = injector.get(services.IncrementsService);
break;
 case 'salaryStructure':
service = injector.get(services.SalaryStructuresService);
break;
 case 'relievingLetter':
service = injector.get(services.RelievingLettersService);
break;
 case 'contactUs':
service = injector.get(services.ContactUsService);
break;
 case 'clientDetails':
service = injector.get(services.ClientDetailsService);
break;
 case 'clientDetail':
service = injector.get(services.ClientDetailsService);
break;
 case 'invoice':
service = injector.get(services.InvoicesService);
break;
 case 'invoiceLineItem':
service = injector.get(services.InvoiceLineItemsService);
break;
 case 'quotation':
service = injector.get(services.QuotationsService);
break;
 case 'quotationLineItem':
service = injector.get(services.QuotationLineItemsService);
break;
 case 'projectDoc':
service = injector.get(services.ProjectDocsService);
break;
 case 'projectItem':
service = injector.get(services.ProjectItemsService);
break;
 case 'invoiceReceipt':
service = injector.get(services.InvoiceReceiptsService);
break;
 case 'receiptLineItem':
service = injector.get(services.ReceiptLineItemsService);
break;
 case 'joiningLetter':
service = injector.get(services.JoiningLettersService);
break;
// <<add-service>>
    }
    //console.log('service', service);
    return service;
  }

}
