import { Injector } from '@angular/core';

 import { AppInjectorBase } from './app/sharedBase/app-injector.service'
//import { AppInjectorBase } from '@ojas-enterprise/baseapp/dist/sharedBase'

export class AppInjector{
  static setInjector(injector: Injector) {

    AppInjectorBase.setInjector(injector);

  }

  static getInjector(): Injector {

    return AppInjectorBase.getInjector();

  }

}
