import { Injectable } from '@angular/core';
import { Increment } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class IncrementsService extends BaseService<Increment> {
    constructor() {
        super('Increment');
    }
}
