import { Injectable } from '@angular/core';
import { HelperServiceBase } from '../sharedBase/helperService';
//import { HelperServiceBase } from '@ojas-enterprise/baseapp/dist/sharedBase';

@Injectable({
  providedIn: 'root'
})
export class HelperService extends HelperServiceBase {

}
