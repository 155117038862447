import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from '../../shared/helperService';

@Component({
  selector: 'app-tracker-btn',
  templateUrl: './tracker-btn.component.html',
  styleUrls: ['./tracker-btn.component.scss'],
})
export class TrackerBtnComponent implements OnInit {

  @Input() type: string;
  @Input() trackerHeading: string;

  constructor(private helperService: HelperService) { }

  ngOnInit() {}

  route(path: string): void {
    this.helperService.routeNavigate(path);
  }

}
