import { Injectable } from '@angular/core';
import { ContactUs } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class ContactUsService extends BaseService<ContactUs> {
    constructor() {
        super('ContactUs');
    }
}
