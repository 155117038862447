import {Pipe, PipeTransform} from '@angular/core';
@Pipe({
  name: 'filter'
})

export class FilterPipe implements PipeTransform {

  transform(value: any[], filterBy: string, column: string, condition = '='): any[] {
      filterBy = filterBy ? filterBy.toLocaleLowerCase() : null;
      if(condition == '='){
        return filterBy ? value.filter((item: any) =>
          item[column].toLocaleLowerCase().indexOf(filterBy) !== -1) : value;
      }

      if(condition == '!='){
        return filterBy ? value.filter((item: any) =>
          item[column].toLocaleLowerCase().indexOf(filterBy) == -1) : value;
      }

      // if(condition == 'in'){
      //   return filterBy ? value.filter((item: any) =>
      //     item[column].toLocaleLowerCase().indexOf(filterBy) == -1) : value;
      // }

      // return filterBy ? value.filter((item: any) =>
      //     item[column].toLocaleLowerCase().indexOf(filterBy) !== -1) : value;
  }
}
