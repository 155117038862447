import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'sipMin'
})

export class SipMinPipe implements PipeTransform {
  transform(value: any[]): any {
    const type = 'SIP';
    const frequency = 'Monthly';
    let minAmout = 0;
    const sipMin = value.filter(v => v.Frequency == frequency && v.SIType == type)
    if (sipMin.length) {
      minAmout = sipMin[0].MinAmount;
    }
    return minAmout;
  }
}
