import { Injectable } from '@angular/core';
import { AppUser } from '../model';
import { BaseService } from '../../shared/baseService';
@Injectable({
    providedIn: 'root'
})

export class AppUsersService extends BaseService<AppUser> {
  
    constructor() {
        super('AppUser');
    }
}
