import { Component, OnInit, Input } from '@angular/core';
// import { HelperService } from ' src/app/shared/helperService';
import { SubSink } from 'subsink';
import { HelperService } from '../../shared/helperService';



@Component({
  selector: 'app-header-content',
  templateUrl: './header-content.component.html',
  styleUrls: ['./header-content.component.scss'],
})
export class HeaderContentComponent implements OnInit {

  @Input() title = '';
  @Input() showMenu = false;
  @Input() showBack = false;
  @Input() isLogoVisible = true;

  userName: string = '';
  userImg: string = '';
  isUserLoggedIn = false;
  subsink = new SubSink();

  loadComplete = false;

  constructor(
    private helperService: HelperService
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.getUserInfo(0);
    }, 100);

    const search = {};
    search['IsActive'] = true;

  }

  getUserInfo(tryCount) {
    if(this.helperService.isUserLoggedIn){
      this.isUserLoggedIn = this.helperService.isUserLoggedIn;
      this.userName = this.helperService.loggedInUser.name;
      this.userImg = this.helperService.loggedInUser.photo;
    }
    else{
      if(tryCount <2 ){
        setTimeout(() => {
          this.getUserInfo(++tryCount);
        }, 200);
      }
    }

  }

  ionViewWillEnter() {

    if (!this.userName) {
      this.helperService.loggedInUserComplete.subscribe((loginInfo: any) => {
        this.isUserLoggedIn = this.helperService.isUserLoggedIn;

        this.userName = loginInfo.name;
        this.userImg = loginInfo.photo;
      });
    }

  }

  ngOnDestroy() {
    this.subsink.unsubscribe();
  }

  goto(path: string):void {
    this.helperService.routeNavigate(path);
  }

}
