import { Injectable } from '@angular/core';
import { ClientDetail } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class ClientDetailsService extends BaseService<ClientDetail> {
    constructor() {
        super('ClientDetail');
    }
}
