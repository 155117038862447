import { Injectable } from '@angular/core';
import { InvoiceLineItem } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class InvoiceLineItemsService extends BaseService<InvoiceLineItem> {
    constructor() {
        super('InvoiceLineItem');
    }
}
