import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
@Input() showLoading  = false;
@Input() delay  = 0;
@Input() type: 'route' | 'dataLoad' | 'pageLoad' | 'pageTrans' | 'blockUILoader' = 'blockUILoader';

  constructor( ) { }

  ngOnInit() {
  }

}
