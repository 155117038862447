import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./pages/auth/guard/authGuard.service";
import { AuthAdminGuard } from "./pages/auth/guard/authGuardAdmin.service";
import { UsersProfileComponent } from "./pages/auth/users-profile/users-profile.component";
import { HomeComponent } from "./pages/home/home.component";

const routes: Routes = [
  { path: "", pathMatch: "full", redirectTo: "login" },
  { path: 'login1', loadChildren: () => import('./pages/admin/logins/logins.module').then(m => m.LoginsModule) },

  { path: "register1", loadChildren: () => import("./pages/auth/register/register.module").then((m) => m.RegisterModule), },
  { path: "login", loadChildren: () => import("./pages/auth/login/login.module").then((m) => m.LoginModule) },
  { path: "verify", loadChildren: () => import("./pages/auth/verification/verification.module").then((m) => m.VerificationModule) },
  { path: 'admin/email-verification', loadChildren: () => import('./pages/auth/email-verification/email-verification.module').then(m => m.EmailVerificationPageModule), canActivate: [AuthGuard] },

  // For Admin Route
  { path: 'admin/enumDetails', loadChildren: () => import('./pages/adminCustom/enumDetails/enumDetails.module').then(m => m.EnumDetailsModule) },
  { path: 'admin/home', loadChildren: () => import('./pages/adminCustom/homes/homes.module').then(m => m.HomesModule), canActivate: [AuthAdminGuard], data: {roles: ['Admin','Owner','Contentwriter'] } },

{ path: 'admin/appUsers', loadChildren: () => import('./pages/adminCustom/appUsers/appUsers.module').then(m => m.AppUsersModule) },
{ path: 'admin/salaries', loadChildren: () => import('./pages/adminCustom/salaries/salaries.module').then(m => m.SalariesModule) },
{ path: 'admin/logins', loadChildren: () => import('./pages/admin/logins/logins.module').then(m => m.LoginsModule) },
{ path: 'admin/offerLatters', loadChildren: () => import('./pages/adminCustom/offerLatters/offerLatters.module').then(m => m.OfferLattersModule) },
// { path: 'admin/homes', loadChildren: () => import('./pages/admin/homes/homes.module').then(m => m.HomesModule) },
{ path: 'admin/configurations', loadChildren: () => import('./pages/adminCustom/configurations/configurations.module').then(m => m.ConfigurationsModule) },
{ path: 'admin/exprienceLatters', loadChildren: () => import('./pages/adminCustom/exprienceLatters/exprienceLatters.module').then(m => m.ExprienceLattersModule) },
{ path: 'admin/increments', loadChildren: () => import('./pages/adminCustom/increments/increments.module').then(m => m.IncrementsModule) },
{ path: 'admin/salaryStructures', loadChildren: () => import('./pages/adminCustom/salaryStructures/salaryStructures.module').then(m => m.SalaryStructuresModule) },
{ path: 'admin/relievingLetters', loadChildren: () => import('./pages/adminCustom/relievingLetters/relievingLetters.module').then(m => m.RelievingLettersModule) },
{ path: 'admin/contactUs', loadChildren: () => import('./pages/admin/contactUs/contactUs.module').then(m => m.ContactUsModule) },
{ path: 'admin/clientDetails', loadChildren: () => import('./pages/adminCustom/clientDetails/clientDetails.module').then(m => m.ClientDetailsModule) },
{ path: 'admin/invoices', loadChildren: () => import('./pages/adminCustom/invoices/invoices.module').then(m => m.InvoicesModule) },
{ path: 'admin/invoiceLineItems', loadChildren: () => import('./pages/adminCustom/invoiceLineItems/invoiceLineItems.module').then(m => m.InvoiceLineItemsModule) },
{ path: 'admin/quotations', loadChildren: () => import('./pages/adminCustom/quotations/quotations.module').then(m => m.QuotationsModule) },
{ path: 'admin/quotationLineItems', loadChildren: () => import('./pages/adminCustom/quotationLineItems/quotationLineItems.module').then(m => m.QuotationLineItemsModule) },
{ path: 'admin/projectDocs', loadChildren: () => import('./pages/adminCustom/projectDocs/projectDocs.module').then(m => m.ProjectDocsModule) },
{ path: 'admin/projectItems', loadChildren: () => import('./pages/adminCustom/projectItems/projectItems.module').then(m => m.ProjectItemsModule) },
{ path: 'admin/invoiceReceipts', loadChildren: () => import('./pages/adminCustom/invoiceReceipts/invoiceReceipts.module').then(m => m.InvoiceReceiptsModule) },
{ path: 'admin/receiptLineItems', loadChildren: () => import('./pages/adminCustom/receiptLineItems/receiptLineItems.module').then(m => m.ReceiptLineItemsModule) },
{ path: 'admin/joiningLetters', loadChildren: () => import('./pages/adminCustom/joiningLetters/joiningLetters.module').then(m => m.JoiningLettersModule) },
// <<route-path-admin>>
  // End Admin Route

  // For AppUser Route
  // { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
    // {path:'home',component:HomeComponent},
    {path:'profile',component:UsersProfileComponent},
{ path: 'webUsers', loadChildren: () => import('./pages/user/webUsers/webUsers.module').then(m => m.WebUsersModule) },
{ path: 'salaries', loadChildren: () => import('./pages/custom/salaries/salaries.module').then(m => m.SalariesModule) },
{ path: 'appUser', loadChildren: () => import('./pages/custom/appUsers/appUsers.module').then(m => m.AppUsersModule) },
{ path: 'offerLatters', loadChildren: () => import('./pages/custom/offerLatters/offerLatters.module').then(m => m.OfferLattersModule) },
{ path: 'home', loadChildren: () => import('./pages/custom/homes/homes.module').then(m => m.HomesModule) },
{ path: 'enumDetails', loadChildren: () => import('./pages/user/enumDetails/enumDetails.module').then(m => m.EnumDetailsModule) },
{ path: 'configurations', loadChildren: () => import('./pages/user/configurations/configurations.module').then(m => m.ConfigurationsModule) },
{ path: 'exprienceLatters', loadChildren: () => import('./pages/custom/exprienceLatters/exprienceLatters.module').then(m => m.ExprienceLattersModule) },
{ path: 'increments', loadChildren: () => import('./pages/custom/increments/increments.module').then(m => m.IncrementsModule) },
{ path: 'salaryStructures', loadChildren: () => import('./pages/custom/salaryStructures/salaryStructures.module').then(m => m.SalaryStructuresModule) },
{ path: 'clientDetails', loadChildren: () => import('./pages/user/clientDetails/clientDetails.module').then(m => m.ClientDetailsModule) },
{ path: 'invoices', loadChildren: () => import('./pages/custom/invoices/invoices.module').then(m => m.InvoicesModule) },
{ path: 'invoiceLineItems', loadChildren: () => import('./pages/custom/invoiceLineItems/invoiceLineItems.module').then(m => m.InvoiceLineItemsModule) },
{ path: 'quotations', loadChildren: () => import('./pages/custom/quotations/quotations.module').then(m => m.QuotationsModule) },
{ path: 'quotationLineItems', loadChildren: () => import('./pages/custom/quotationLineItems/quotationLineItems.module').then(m => m.QuotationLineItemsModule) },
{ path: 'projectDocs', loadChildren: () => import('./pages/custom/projectDocs/projectDocs.module').then(m => m.ProjectDocsModule) },
{ path: 'projectItems', loadChildren: () => import('./pages/custom/projectItems/projectItems.module').then(m => m.ProjectItemsModule) },
{ path: 'invoiceReceipts', loadChildren: () => import('./pages/custom/invoiceReceipts/invoiceReceipts.module').then(m => m.InvoiceReceiptsModule) },
{ path: 'relievingLetters', loadChildren: () => import('./pages/custom/relievingLetters/relievingLetters.module').then(m => m.RelievingLettersModule) },
{ path: 'receiptLineItems', loadChildren: () => import('./pages/custom/receiptLineItems/receiptLineItems.module').then(m => m.ReceiptLineItemsModule) },
{ path: 'joiningLetters', loadChildren: () => import('./pages/user/joiningLetters/joiningLetters.module').then(m => m.JoiningLettersModule) },
// <<route-path-user>>
  // End AppUser Route
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
