import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { HelperService } from '../helperService';

@Directive({
  selector: '[libScrollIntoView]'
})
export class ScrollIntoViewDirective {
  @Input() libScrollIntoView : 'top' | '';
  @Input() scrollTop : number;
  constructor(private _elementRef: ElementRef, private helperService: HelperService) {

    setTimeout(() => {
      if(this.libScrollIntoView == "top" ) {
        this.helperService.scrollToTop();
      } else{
        console.log("scroll control", this._elementRef.nativeElement)
        let elem = this._elementRef.nativeElement as HTMLElement;
        elem.scrollIntoView({ behavior: 'smooth', block: 'start' });     
      }
    }, 50)
  }

}
