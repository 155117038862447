import { Injectable } from '@angular/core';
import { Blog } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class BlogsService extends BaseService<Blog> {
    constructor() {
        super('Blog');
    }
}
