import { Injectable } from '@angular/core';
import { WebUser } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class WebUsersService extends BaseService<WebUser> {
    constructor() {
        super('WebUser');
    }
}
