import { Injectable } from '@angular/core';
import { Quotation } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class QuotationsService extends BaseService<Quotation> {
    constructor() {
        super('Quotation');
    }
}
