import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { HelperService } from '../../../shared/helperService';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private helperService: HelperService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (! this.helperService.isUserLoggedIn) {
      this.helperService.navigationUrl = state.url;
      this.helperService.routeNavigate('login');
      return false;
    } else {
      return true;
    }
  }
}
