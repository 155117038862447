import { Component, HostListener, OnInit } from '@angular/core';
import { HelperService } from '../../shared/helperService';
import { LoginsService } from '../../core/service';
import { AppInjector } from '../../../app-injector.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loggedInUser: any;
  userInfo: any = null;
  isAdmin = false;
  constructor(
    public helperService: HelperService,
    public router: Router
  ) {
    this.helperService.loginChange.subscribe((loginType) => {
      this.loggedInUser = this.helperService.getLoggedInUserInfo();

    });
  }

  ngOnInit(): void {
    this.userInfo = this.helperService.getLoggedInUserInfo();
    this.loggedInUser = this.helperService.getLoggedInUserInfo();
    this.isAdmin = this.helperService.isAdminUser;
    console.log(this.loggedInUser,' loggedInUser')
    setTimeout(() => {
      this.userInfo = this.helperService.getLoggedInUserInfo();
      this.loggedInUser = this.helperService.getLoggedInUserInfo();
      this.isAdmin = this.helperService.isAdminUser;
    }, 1000);
    setTimeout(() => {
      this.userInfo = this.helperService.getLoggedInUserInfo();
      this.loggedInUser = this.helperService.getLoggedInUserInfo();
      this.isAdmin = this.helperService.isAdminUser;
    }, 2000);
  }
  ngAfterViewInit() {

  }
  private prevScrollpos = window.pageYOffset;
  navbarTopStyle = '0';

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const currentScrollPos = window.pageYOffset;
    this.navbarTopStyle = this.prevScrollpos > currentScrollPos ? '0' : '-100px';
    this.prevScrollpos = currentScrollPos;
  }
  logout() {
    const injector = AppInjector.getInjector();
    const loginSvc = injector.get(LoginsService);

    loginSvc.clearData();
    this.helperService.logout();
  }
  goto(path: string) {
    this.router.navigate([path]);
  }
}
