import { Component, OnInit, ElementRef, ViewChild, Renderer2, QueryList } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @ViewChild('box') box: QueryList<ElementRef>;
  @ViewChild('li') li: ElementRef;
  closeMenu: boolean = true;
  constructor(private renderer: Renderer2) { }
  clickedElement: Subscription = new Subscription();
  ngOnInit(): void {

  }
  ngAfterViewInit() {
    // for(let a=0; a<this.box.nativeElement; a++){
    //   alert('hello')
    // }


      //   this.clickedElement = fromEvent(this.box.nativeElement, 'click').subscribe(() => {
      //   if(this.closeMenu){
      //   this.renderer.addClass(this.li.nativeElement, 'showMenu');

      //   this.closeMenu=false;
      //   }else{
      //     this.renderer.removeClass(this.li.nativeElement, 'showMenu');
      //     this.closeMenu=true;
      //   }
      // });
    //   this.clickedElement = fromEvent(this.box.nativeElement, 'click').subscribe(() => {
    //     this.renderer.removeClass(this.li.nativeElement, 'showMenu');
    //     this.closeMenu = true;

    // }
    }
    fun(){
      if(this.closeMenu){
        this.renderer.addClass(this.li.nativeElement, 'showMenu');
        this.closeMenu=false;
        }else{
          this.renderer.removeClass(this.li.nativeElement, 'showMenu');
          this.closeMenu=true;
        }
    }

}
