import { Injectable } from '@angular/core';
import { RelievingLetter } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class RelievingLettersService extends BaseService<RelievingLetter> {
    constructor() {
        super('RelievingLetter');
    }
}
