import { Injectable } from '@angular/core';
import { Invoice } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class InvoicesService extends BaseService<Invoice> {
    constructor() {
        super('Invoice');
    }
}
