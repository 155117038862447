import { Pipe, PipeTransform } from '@angular/core';
import { EnvService } from '../../core/env.service';
import { IFileUpload } from '../file-upload/file-upload.component';
@Pipe({
  name: 'uploadImgPath'
})

export class UploadImgPathPipe implements PipeTransform {
  constructor(private env: EnvService) {
    //console.log('env service for FileUpload', env.apiUrl);
  }
  transform(value: string, index: number = 0): string {
    const notFoundImgPath = '';
    try{
      if (value) {
        const files = JSON.parse(value);
        return this.getImgUrl(files[index]);
      }
    }
    catch {

    }

    return notFoundImgPath;
  }

  getImgUrl(file: IFileUpload) {
    return this.env.apiUrl + 'ImportFiles/' + file.filePath;
  }
}
