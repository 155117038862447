import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'inrCurrency'
})

export class InrCurrencyPipe implements PipeTransform {
  transform(value: number) {
    if(value != null && value == 0) return 0;
    if (!value) return '';
    const numVal = value.toString().split('.');

    const x = numVal[0];
    let lastThree = x.substring(x.length - 3);
    const otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== '') {
      lastThree = ',' + lastThree;
    }
    let pointVal = '';
    if (numVal.length > 1) {
      pointVal = '.' + numVal[1];
    }
    return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + pointVal;
  }

}
