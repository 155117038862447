import { Injectable } from '@angular/core';
import { OfferLatter } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class OfferLattersService extends BaseService<OfferLatter> {
    constructor() {
        super('OfferLatter');
    }
}
