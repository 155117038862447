import {
  Component,
  OnInit,
  Injectable,
  HostListener,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  Event,
  RouteConfigLoadStart,
} from "@angular/router";
import { HelperService } from "./shared/helperService";
import { LookupService } from "./shared/lookupService";
import { AppInjector } from "../app-injector.service";
import { LoginsService } from "./core/service";
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  showLoadingIndicator = true;
  menuActive = true;
  newsActive = false;
  isAdmin = false;
  isUserLoggedIn = false;
  loadIntialize = false;
  isMenuVisible: boolean = false;
  isOverlayVisible: boolean = false;
  loadComponent = false;

  loggedInUser: any;
  currentRoute: string;
  activeMenu: string = "";
  isWindowScroll: boolean = false;
  isLeftMenuFixed: boolean = false;
  isRightPartFixed: boolean = false;
  showMobileView: boolean = false;
  isMobSearchVisible: boolean = false;
  isMobProfileVisible: boolean = false;
  toggleNotification: boolean = false;
  isBlockVisible: boolean = true;
  isLoaderVisible: boolean = true;
  hideTooltip: boolean = false;
  userName:string;
  sidebar:boolean=true;
  url:any;
  items: MenuItem[];
  menuList: any[];


  loginLoadingComplete = false;


  constructor(
    private translate: TranslateService,
    private router: Router,
    public helperService: HelperService,
    public lookupService: LookupService,
    private renderer:Renderer2,
    private primengConfig: PrimeNGConfig

  )
  {
    this.menuList = [
      {name:"home", subMenu:"home"},
      {name:"platters", subMenu:"platters"},
      {name:"profile", subMenu:"profile"},
      {name:"mandates", subMenu:"mandates"},
      {name:"canRegisters", subMenu:"canRegisters"},
      {name:"clientInvestments", subMenu:"clientInvestments"},
      {name:"clientInvestmentPlatters", subMenu:"clientInvestmentPlatters"},
      {name:"clientInvestmentPortfolios", subMenu:"clientInvestmentPortfolios"},
      {name:"clientInvestmentSummaries", subMenu:"clientInvestmentSummaries"},
      {name:"commonDatas", subMenu:"commonDatas"},
      {name:"mfuOrders", subMenu:"mfuOrders"},
      {name:"home", subMenu:"home"},
      {name:"appUsers", subMenu:"appUsers"},
      {name:"salaries", subMenu:"salaries"},
      {name:"offerLatters", subMenu:"offerLatters"},
      {name:"enumDetails", subMenu:"enumDetails"},
      {name:"exprienceLatters", subMenu:"exprienceLatters"},
      {name:"increments", subMenu:"increments"},
      {name:"salaryStructures", subMenu:"salaryStructures"},
      {name:"relievingLetters", subMenu:"relievingLetters"},
      {name:"clientDetails", subMenu:"clientDetails"},
      {name:"projectDocs", subMenu:"projectDocs"},
      {name:"quotations", subMenu:"quotations"},
      {name:"invoices", subMenu:"invoices"},
      {name:"invoiceReceipts", subMenu:"invoiceReceipts"},
      {name:"contactUs", subMenu:"contactUs"},
      {name:"home", subMenu:"home"},
      {name:"home", subMenu:"home"},
  ]
    translate.setDefaultLang("en");
    const injector = AppInjector.getInjector();
    this.helperService.loginChange.subscribe((loginType) => {
      this.loggedInUser = this.helperService.getLoggedInUserInfo();
      this.isAdmin = this.helperService.isAdminUser;
      this.isUserLoggedIn = this.helperService.isUserLoggedIn;


    });

    this.router.events.subscribe((routerEvent: Event) => {
      this.isMobSearchVisible = false;
      this.isMobProfileVisible = false;
      this.toggleNotification = !this.toggleNotification;
      if (routerEvent instanceof RouteConfigLoadStart) {
        this.showLoadingIndicator = true;
        this.isLoaderVisible = true;
      }
      if (routerEvent instanceof NavigationStart) {
        ////console.log('router.events', routerEvent.url);
        this.showLoadingIndicator = true;
        this.isLoaderVisible = true;
        let currentUrl = routerEvent.url.substr(
          routerEvent.url.indexOf("/") + 1
        );
        this.currentRoute = currentUrl;
        this.activeMenu = this.getActiveMenu(routerEvent.url);
      }

      if (
        routerEvent instanceof NavigationEnd ||
        routerEvent instanceof NavigationCancel ||
        routerEvent instanceof NavigationError
      ) {
        setTimeout(() => {
          this.showLoadingIndicator = false;
        }, 500);

        setTimeout(() => {
          this.isLoaderVisible = false;
        }, 2000);

        setTimeout(() => {
          const url = this.router.routerState.snapshot.url.toLowerCase();
          if (url.indexOf("/foundation/") >= 0) {
            this.helperService.scrollToSection("child-foundation");
          } else {
            this.helperService.scrollToSection("main-page");
          }
        }, 200);
      }
    });

  }

  getActiveMenu(url: string): string {
    if (url.toLowerCase().indexOf("platters") >= 0) return "platters";
    if (url.toLowerCase().indexOf("investment-report") >= 0)
      return "investment-report";
    if (url.toLowerCase().indexOf("mandates") >= 0) return "mandates";
    if (url.toLowerCase().indexOf("bseRegistrations".toLowerCase()) >= 0)
      return "bseRegistrations";
    if (url.toLowerCase().indexOf("mfUserOrders".toLowerCase()) >= 0)
      return "mfUserOrders";
    if (
      url.toLowerCase().indexOf("edExpenses".toLowerCase()) >= 0 ||
      url.toLowerCase().indexOf("edIncomes".toLowerCase()) >= 0
    )
      return "tracker";
    if (url.toLowerCase().indexOf("contact-us") >= 0) return "contact-us";

    return "";
  }
  ngOnInit() {
    this.primengConfig.ripple = true;
    setTimeout(() => {
      this.lookupService.getDataEnum("").subscribe((list) => {
        this.loadIntialize = true;
      });
      this.loggedInUser = this.helperService.getLoggedInUserInfo();
      this.isAdmin = this.helperService.isAdminUser;
      this.isUserLoggedIn = this.helperService.isUserLoggedIn;
    }, 50);


    GoogleAuth.initialize({
      clientId: '927555018564-ri84knhsc1bv29ki83ffa4msj42tt1vr.apps.googleusercontent.com',
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    });
    setTimeout(() => {
      this.loadIntialize = true;
      this.loadComponent = true;
        }, 1000);

    this.showMobileView = this.helperService.isMobile;

    if (!this.helperService.isMobile) {
      this.isMobSearchVisible = true;
    } else {
      this.isMobSearchVisible = false;
    }
    this.items = [
      {
      label: 'Options',
      items: [
        {
          label: 'Profile',
          icon: 'pi pi-refresh',
          command: () => {
            this.router.navigateByUrl('profile')
          }
      },
      {
          label: 'Settings',
          icon: 'pi pi-user',
          command: () => {
          }
      },
      {
          label: 'Logout',
          icon: 'pi pi-sign-out',
          command: () => {
            this.logout();
          }
      }
      ]},

  ];
  }
  ngAfterViewInit(): void {
    setTimeout(()=>{
      // this.userName=this.helperService.loggedInUser.firstName;
    },100)



  }
  goto(path: string) {
    this.helperService.clearSearchHistory();
    this.helperService.routeNavigate(path);
    this.helperService.setRouteData(null);
    this.isMenuVisible = false;
    this.isOverlayVisible = false;
  }
  route(path: string) {
    this.helperService.clearSearchHistory();
    this.helperService.routeNavigate(path);
    this.helperService.setRouteData(null);
    this.isMenuVisible = false;
    this.isOverlayVisible = false;
  }
  onMenuButtonClick(event: any) {
    this.menuActive = !this.menuActive;
    event.preventDefault();
  }

  logout() {
    const injector = AppInjector.getInjector();
    const loginSvc = injector.get(LoginsService);
    loginSvc.clearData();
    this.helperService.logout();
  }

  toggleMenu(): void {
    this.isMenuVisible = !this.isMenuVisible;
    this.isOverlayVisible = !this.isOverlayVisible;
  }

  @HostListener("window:scroll", []) onWindowScroll() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      this.isWindowScroll = true;
    } else {
      this.isWindowScroll = false;
    }


    if (
      document.body.scrollTop > 450 ||
      document.documentElement.scrollTop > 450
    ) {
      this.isLeftMenuFixed = true;
    } else {
      this.isLeftMenuFixed = false;
    }

    if (
      document.body.scrollTop > 1030 ||
      document.documentElement.scrollTop > 1030
    ) {
      this.isRightPartFixed = true;
    } else {
      this.isRightPartFixed = false;
    }
  }

  gotoTop(): void {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  toggleMobileProfile(event: any): void {
    event.stopPropagation();
    this.isMobProfileVisible = !this.isMobProfileVisible;
  }

  toggleMobileSearch(event: any): void {
    event.stopPropagation();
    this.isMobSearchVisible = !this.isMobSearchVisible;
  }

  @HostListener("click", ["$event"])
  onClick(event: any): void {
    this.isMobProfileVisible = false;
    this.isMobSearchVisible = false;
    this.toggleNotification = false;
  }
  @HostListener('window:scroll', ['$event']) fun() {
    if (window.innerWidth < 760) {
      this.hideTooltip = true;
    } else {
      this.hideTooltip = false;
    }
  }
  // For Style
  closeSideMenu(){
      this.sidebar=!this.sidebar;
  }





}
