import { Injectable } from '@angular/core';
import { ProjectDoc } from '../model';
import { BaseService } from '../../shared/baseService';

@Injectable({
    providedIn: 'root'
})

export class ProjectDocsService extends BaseService<ProjectDoc> {
    constructor() {
        super('ProjectDoc');
    }
}
